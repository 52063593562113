import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import ContentRow from '../../components/ContentRow';
import { commonHeaderColor } from '../../util';


const Page = ({ data }) => (
  <>
    <SEO
      title="Get Your NeoSurf Voucher Quickly And Securely"
      description="You can use Neosurf to shop, play, or subscribe to your favorite services without any complications."
      alternateLanguagePages={[
        {
          path: 'buy-neosurf',
          language: 'en'
        },
        {
          path: 'acheter-neosurf',
          language: 'fr'
        },
        {
          path: 'buy-neosurf',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://bit.ly/2AlU6Ch">
            <Img fluid={data.myneosurf.childImageSharp.fluid} alt="MyNeoSurf Logo" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">Buy Neosurf</h1>
            <p className="textColorDark">Get Your NeoSurf Voucher Quickly And Securely!</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <p className="textColorDark">Neosurf is one of the most convenient and secure ways to make payments online. You can use Neosurf to shop, play, or subscribe to your favorite services without any complications. It is a hassle-free, cash-based payment method that keeps your personal information safe, letting you shop with peace of mind.</p>
      <p className="textColorDark">Buying a Neosurf voucher is very easy. You can purchase Neosurf at authorized points of sale, such as local convenience stores, supermarkets, kiosks, and other vendors. Vouchers come in different values, which allows you to choose an amount that fits your needs with ease. Because of this flexibility, Neosurf is a great option for users who prefer to manage their spending effectively. For example, if you are trying to stick to a budget, you can purchase Neosurf with a planned amount, thus eliminating the risk of overspending.</p>
      <p className="textColorDark">Privacy is one of the biggest advantages of Neosurf because, unlike traditional payment methods, such as credit cards, it does not require you to share sensitive personal or financial information. This is the perfect choice for those who prefer security and anonymity while doing online transactions.</p>
      <p className="textColorDark">Neosurf is widely accepted on thousands of online platforms worldwide, including gaming websites, e-commerce stores, subscription services, and more. Gamers love Neosurf because it allows quick and easy top-ups for gaming accounts without any delays, which is why it is perfect for casinos, too.</p>
      <p className="textColorDark">Buying Neosurf is great for those who cannot access traditional banking services or credit cards. With Neosurf, you can enjoy paying for online services without needing a bank account. For those who prefer using cash for online transactions, Neosurf is the perfect solution.</p>
      <p className="textColorDark">If you are ready to purchase a Neosurf voucher, start by finding a trusted point of sale near you or a verified online retailer. After you get a voucher, using it is very simple. All you have to do is enter the code from the voucher on any platform that accepts Neosurf. There are no hidden fees or complicated procedures, which makes it a straightforward way to pay.</p>
      <p className="textColorDark">Neosurf has become a global favorite because it is easy and reliable. If you have been looking for a secure and easy way to shop online, a Neosurf voucher will give you that experience, so get a Neosurf voucher now!</p>
    </ContentRow>
  </>
)

export default Page;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    myneosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/myneosurf-header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
